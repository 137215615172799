<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="fileinput">
      <app-input v-model="name" name="Dosya Adı" label="Dosya Adı:" placeholder="Dosya Adı..." rules="required|max:100" />
      <app-select-input v-model="fileGroup" name="Dosya Türü" label="Dosya Türü:" placeholder="Dosya Türü Seçiniz..." select_label="value" :options="fileGroups" rules="required" />
      <app-file-input v-model="file" :accept="$acceptMimes(['csv', 'xls', 'xlsx', 'doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png'])" :size-m-b-limit="5" label="Dosya:" requires />
      <hr />
      <small>
        - Sadece Excel, Word, PDF veya JPG dosya formatı yüklenebilir. <br />
        - En fazla 5 MB boyutunda dosya yükleyebilirsiniz.
      </small>
    </validation-observer>
    <hr />
    <app-button icon="UploadIcon" icon-position="left" size="md" text="Yükle" block @click="uploadFile" />
    <hr />
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      name: null,
      file: null,
      fileGroup: null,
      fileGroups: [
        { group: "plan", value: "Risk Değerlendirme Raporu" },
        { group: "plan", value: "Acil Durum Eylem Planı" },
        { group: "plan", value: "Yıllık Çalışma Planı" },
        { group: "plan", value: "Yıllık Değerlendirme Planı" },
        { group: "document", value: "İSG Evrağı" },
        { group: "document", value: "Check List" },
        { group: "document", value: "Talimat" },
        { group: "document", value: "Form" },
        { group: "document", value: "Rapor" },
      ],
    };
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("name", this.name);
      formData.append("fileGroup", JSON.stringify(this.fileGroup));
      return formData;
    },
    uploadFile() {
      this.$validate(this.$refs.fileinput, () => {
        this.$axios
          .post("/doc-templates", this.setData(), { loading: "sidebar" })
          .then((response) => {
            this.$store.commit("newDocTemplate", response.data);
            this.$store.commit("cleanAppSidebar");
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: "Dosya sisteme başarıyla yüklendi." });
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu" });
          });
      });
    },
  },
};
</script>

<style></style>
