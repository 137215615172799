<template>
  <app-overlay>
    <db-searchable-table class="border" v-model="filters" :items="items" :fields="fields" :total-rows="totalRows" searchable @changed="getFiles">
      <template #modalbutton>
        <app-button text="Dosya Yükle" icon="UploadIcon" size="md" @click="$showAppSidebar('Doküman Şablonu Ekle', DocTemplateForm)" />
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="getFile(item)">
            <feather-icon icon="EyeIcon" class="mr-50" />
            Görüntüle
          </b-dropdown-item>
          <b-dropdown-item @click="deleteFile(item._id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            Sil
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import { baseURL } from "@/utils/appAxios";
import DocTemplateForm from "./DocTemplateForm.vue";

export default {
  data() {
    return {
      totalRows: 0,
      filters: {},
      fields: [
        { key: "name", label: "Dosya" },
        { key: "fileGroup.value", label: "Dosya" },
        { key: "createdAt", label: "Oluşturulma Tarihi", formatDateTime: true },
        { key: "actions", label: "Eylemler" },
      ],
      baseURL,
      DocTemplateForm,
    };
  },
  computed: {
    items() {
      return this.$store.getters.docTemplates;
    },
  },
  mounted() {
    this.getFiles();
  },
  destroyed() {
    this.$store.commit("setDocTemplates", []);
  },
  methods: {
    getFiles() {
      this.$axios
        .get("/doc-templates/paginated", { params: this.filters, loading: "table" })
        .then((res) => {
          this.$store.commit("setDocTemplates", res.data.data);
          this.totalRows = res.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFile(item) {
      this.$axios
        .get(`/doc-templates/${item._id}`, { loading: "table", responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], { type: result.headers["content-type"] });
          const url = window.URL.createObjectURL(blob);
          const newWindow = window.open(url, "_blank");
          if (!newWindow) {
            alert("Pop-up engelleyiciniz aktif durumdadır. Dökümanı görüntüleyebilmek için lütfen engelleyiciyi kapatınız.");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
        });
    },
    deleteFile(id) {
      this.$confirm({ message: "Şablon Sistemden Silinecek Emin misiniz?" }, () => {
        this.$axios
          .delete(`/doc-templates/${id}`, { loading: "table" })
          .then((res) => {
            this.getFiles();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Şablon sistemden başarıyla silindi.",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<style></style>
